<template>
  <div class="footer">
    <div class="footer-left">
      <div class="cards">
        <img src="https://aqsc.jystudy.com/HZImage/cloudplatform.png" alt="" />
        <div class="card-name">杭州市应急管理培训中心</div>
      </div>
      <div class="cards">
        <img src="https://aqsc.jystudy.com/HZImage/erwm.jpg" alt="" />
        <div class="card-name">杭州应急管理</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="footer-right">
      <img src="https://aqsc.jystudy.com/HZImage/pc/images/sLogo.png" alt="" />
      <div class="textBox">
        <div>版权所有©2020 杭州应急管理培训云平台</div>
        <div>
          备案号:
          <a
            href="https://beian.miit.gov.cn/"
            style="color: #fff"
            target="_blank"
            >浙ICP备14033301号-1</a
          >
        </div>
        <div>联系电话：0571-28990788转8006</div>
        <div>联系地址：杭州市拱墅区东新路重机巷58号</div>
        <div>课程质量监督电话：85342055</div>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.footer {
  min-width: 1200px;
  height: 260px;
  background-color: #0b64c2;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-left {
    display: flex;
    .cards:nth-last-child(1) {
      margin-right: 0;
    }
    .cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 44px;
      > img {
        width: 130px;
        height: 130px;
        margin-bottom: 15px;
      }
      .card-name {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
  .line {
    width: 1px;
    height: 161px;
    border: 1px solid #ffffff;
    opacity: 0.4;
    margin: 0 82px;
  }
  .footer-right {
    > img {
      width: 310px;
      height: 48px;
      margin-bottom: 20px;
    }
    .textBox {
      font-size: 14px;
      color: #ffffff;
      line-height: 24px;
    }
  }
}
</style>
